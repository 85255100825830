import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({urlVideo}) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:py-2 py-10">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                        <img className="w-[400px] left-2 top-64 md:absolute" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2FA%C3%B1os%20de%20experiencia%2F12_years_Mesa%20de%20trabajo%201.png?alt=media&token=79d0d642-edde-43a6-847b-87807785ad2e" alt="revexperience" />
                        <img className="w-[300px] right-2 top-60 md:absolute" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsatisfaction%20guarenteed%20google-01-01.png?alt=media&token=ce80dadb-58f4-4b62-9f47-e01e3e7dda6b" alt="review" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;